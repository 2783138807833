<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import Preloader from "@/components/widgets/preloader";
    import Stat from "@/components/widgets/stat";
    import OperationsGrid from "@/components/subaccount/wallet-operations-grid";
    import EventBus from '../../../event-bus'

    import Repository from "../../../app/repository/repository-factory";

    const SubaccountRepository = Repository.get("SubaccountRepository");

    /**
     * Contacts-Profile component
     */
    export default {
        components: {Layout, PageHeader, Stat, Preloader, OperationsGrid},
        data() {
            return {
                title: "Profil podopiecznego",
                preloader: true,
                fullReload: 0,
                items: [

                ],
                subaccountData: {},
                statData: null,
                statMainData: null
            };
        },
        created() {
            this.getSubaccount(this.$route.params.id);
            EventBus.$on('changeWalletSubaccount', () => {
                this.getSubaccount(this.$route.params.id);
            })
        },
        watch: {
            // eslint-disable-next-line no-unused-vars
            '$route.params.id'(newId, oldId) {
                this.getSubaccount(newId);
                this.fullReload += 1;
            }
        },
        methods: {
            getSubaccount(id) {
                if (id) {
                    this.preloader = true;
                    SubaccountRepository.getItem(id).then((response) => {
                        this.subaccountData = response.data;
                        this.setStatsData();
                        this.preloader = false;
                    }).catch(error => {
                        this.preloader = false;
                        console.log(error);
                    })
                }
            },
            setStatsData() {
                this.statData = [
                    {
                        icon: "fas fa-user",
                        title: "Konto imienne",
                        value: (this.subaccountData.personalAccountBalance.toFixed(2)) + " ZŁ"
                    },
                    {
                        icon: "fas fa-hand-holding-heart",
                        title: "Zbiórka publiczna",
                        value: (this.subaccountData.publicAccountBalance.toFixed(2)) + " ZŁ"
                    },
                    {
                        icon: "fas fa-hand-holding-usd",
                        title: "Konto 1%",
                        value: (this.subaccountData.onePercentAccountBalance.toFixed(2)) + " ZŁ"
                    },
                    {
                        icon: "fas fa-hands-helping",
                        title: '<span style="color: #9e0000; ">Pomoc fundacji</span>',
                        value: ((this.subaccountData.foundationAccountBalance) * (-1)).toFixed(2) + " ZŁ"
                    }
                ];

                this.statMainData = [
                    {
                        icon: "fas fa-chart-pie",
                        title: "Saldo całościowe",
                        value: (this.subaccountData.personalAccountBalance + this.subaccountData.publicAccountBalance + this.subaccountData.onePercentAccountBalance).toFixed(2) + " ZŁ"
                    }
                ];
            }
        }
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <preloader v-if="preloader"></preloader>

        <div v-if="preloader === false" id="content">
            <div class="row">
                <div class="col-xl-12">

                    <div class="row">
                        <div class="col-xl-6">
                            <div class="card overflow-hidden" style="min-height: 480px;">
                                <div class="bg-light">
                                    <div class="row">
                                        <div class="col-sm-12 col-lg-6">
                                            <div class="text-primary p-3 mt-4 ml-3">
                                                <h1 class="text-primary">{{ statMainData[0].value }}</h1>
                                                <p>Saldo całościowe</p>
                                            </div>
                                        </div>
                                        <div class="col-6 align-self-end">
                                            <img src="@/assets/images/vector_children0.png" alt
                                                 class="img-fluid imageResizeActive"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body pt-0">
                                    <div class="row">
                                        <div class="col-sm-6">

                                            <div v-if="subaccountData.profileImageUrl == ''"
                                                 class="avatar-md profile-user-wid mb-4">
                                            <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                                              {{subaccountData.firstName.substring(0,1).toLocaleUpperCase()}}
                                            </span>
                                            </div>

                                            <div v-if="subaccountData.profileImageUrl !== ''"
                                                 class="avatar-md profile-user-wid mb-4">
                                                <img :src="subaccountData.profileImageUrl"
                                                     class="img-thumbnail rounded-circle"/>
                                            </div>

                                            <h5 class="font-size-15 text-truncate">{{ subaccountData.firstName }} {{
                                                subaccountData.lastName }}</h5>
                                            <p class="text-muted mb-0 text-truncate"><a
                                                    :href="'mailto:' + subaccountData.email">{{ subaccountData.email
                                                }}</a></p>
                                        </div>

                                        <div class="col-sm-6">
                                            <div class="pt-4">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <p class="text-muted mb-0">{{ subaccountData.addressStreet
                                                            }}<br>{{ subaccountData.addressPostcode }} {{
                                                            subaccountData.addressCity }}</p>

                                                    </div>
                                                </div>
                                                <div class="mt-4">

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-muted mb-4"></p>
                                    <div class="table-responsive mb-0">
                                        <table class="table">
                                            <tbody>
                                            <tr>
                                                <th scope="row">PESEL:</th>
                                                <td>{{ subaccountData.pesel }}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Data urodzenia:</th>
                                                <td>{{ subaccountData.dateOfBirth }}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Numer konta:</th>
                                                <td>{{ subaccountData.bankAccount }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-6">
                            <div class="card" style="min-height: 480px;">
                                <div class="card-body">
                                    <h4 class="card-title">Dane podstawowe</h4>
                                    <div class="table-responsive mb-0">
                                        <table class="table">
                                            <tbody>

                                            <tr>
                                                <th scope="row">Diagnoza medyczna:</th>
                                                <td>{{ subaccountData.medicalDiagnosis }}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Konto aktywne?</th>
                                                <td v-if="subaccountData.isActive">Tak</td>
                                                <td v-if="!subaccountData.isActive" style="color: #9e0000">Nie</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Opiekun:</th>
                                                <td v-if="subaccountData.guardian1Name !== ''">
                                                    {{ subaccountData.guardian1Name }}<br><br>
                                                    Tel: {{ subaccountData.guardian1Phone }}<br>
                                                    Email: <a :href="'mailto:' + subaccountData.guardian1Email">{{
                                                    subaccountData.guardian1Email}}</a>
                                                </td>
                                                <td v-if="subaccountData.guardian1Name === ''">
                                                    brak
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Opiekun:</th>
                                                <td v-if="subaccountData.guardian2Name !== ''">
                                                    {{ subaccountData.guardian2Name }}<br><br>
                                                    Tel: {{ subaccountData.guardian2Phone }}<br>
                                                    Email: <a :href="'mailto:' + subaccountData.guardian2Email">{{
                                                    subaccountData.guardian2Email}}</a>
                                                </td>
                                                <td v-if="subaccountData.guardian2Name === ''">
                                                   brak
                                                </td>
                                            </tr>

                                            <tr>
                                                <th scope="row">Uwagi:</th>
                                                <td>{{ subaccountData.notes }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-xl-12">
                    <div class="row">
                        <div v-for="stat of statData" :key="stat.icon" class="col-md-4 col-lg-3">
                            <Stat :icon="stat.icon" :title="stat.title" :value="stat.value"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-body">

                            <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted" :key="fullReload">
                                <b-tab lazy active>
                                    <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                                        <span class="d-none d-sm-inline-block">Wszystkie</span>
                                    </template>
                                    <OperationsGrid
                                            name="table-wo-0"
                                            v-bind:excelFilename="this.subaccountData.lastName + '_wszystkie_konta'"
                                            v-bind:wallet-uuid="subaccountData.walletUuid"
                                            v-bind:wallet-version="subaccountData.walletVersion"
                                    ></OperationsGrid>
                                </b-tab>

                                <b-tab lazy>
                                    <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                                        <span class="d-none d-sm-inline-block">Konto imienne</span>
                                    </template>
                                    <OperationsGrid
                                            name="table-wo-1"
                                            v-bind:excelFilename="this.subaccountData.lastName + '_konto_imienne'"
                                            v-bind:wallet-uuid="subaccountData.walletUuid"
                                            v-bind:wallet-version="subaccountData.walletVersion"
                                            :typeAccount=1
                                    ></OperationsGrid>
                                </b-tab>

                                <b-tab lazy>
                                    <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                                        <span class="d-none d-sm-inline-block">Zbiórka publiczna</span>
                                    </template>
                                    <OperationsGrid
                                            name="table-wo-2"
                                            v-bind:excelFilename="this.subaccountData.lastName + '_zbiorka_publiczna'"
                                            v-bind:wallet-uuid="subaccountData.walletUuid"
                                            v-bind:wallet-version="subaccountData.walletVersion"
                                            :typeAccount=2
                                    ></OperationsGrid>
                                </b-tab>

                                <b-tab lazy>
                                    <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                                        <span class="d-none d-sm-inline-block">Konto 1%</span>
                                    </template>
                                    <OperationsGrid
                                            name="table-wo-3"
                                            v-bind:excelFilename="this.subaccountData.lastName + '_konto_1procent'"
                                            v-bind:wallet-uuid="subaccountData.walletUuid"
                                            v-bind:wallet-version="subaccountData.walletVersion"
                                            :typeAccount=3
                                    ></OperationsGrid>
                                </b-tab>

                                <b-tab lazy>
                                    <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                                        <span class="d-none d-sm-inline-block" style="color: #9e0000;">Pomoc fundacji</span>
                                    </template>
                                    <OperationsGrid
                                            name="table-wo-4"
                                            v-bind:excelFilename="this.subaccountData.lastName + '_wyplacone_z_konta_fundacji'"
                                            v-bind:wallet-uuid="subaccountData.walletUuid"
                                            v-bind:wallet-version="subaccountData.walletVersion"
                                            :typeAccount=4
                                    ></OperationsGrid>
                                </b-tab>

                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>

            <!-- end row -->
        </div>
    </Layout>
</template>
