<script>
    export default {
        props: {
            data: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                preloader: true,
            }
        },
        methods: {}
    };
</script>

<template>
    <div>
        <p class="mb-2">
            Typ konta:
            <span class="font-weight-bold">{{ data.typeAccount }}</span>
        </p>
        <p class="mb-2">
            Typ operacji:
            <span class="font-weight-bold">{{ data.typeOperation }}</span>
        </p>
        <p class="mb-4">
            Kwota:
            <span class="font-weight-bold">{{ data.amountMoney.toFixed(2) }} zł <span class="font-weight-normal font-size-11" v-if="data.correct !== null"> (po korekcie)</span></span>
        </p>

        <p class="mb-2">
            Data:
            <span class="font-weight-bold">{{ data.time }}</span>
        </p>
        <p class="mb-2"
           v-if="data.typeOperation == 'Wypłata'">
            Szczegóły dokumentu:
            <span class="font-weight-bold">{{ data.payOutDocumentDetails }}</span>
        </p>
        <p class="mb-2">
            Utworzone przez:
            <span class="font-weight-bold">{{ data.createdBy.name }}</span>
        </p>
        <p class="mb-2">
            Utworzone dnia:
            <span class="font-weight-bold">{{ data.createdAt }}</span>
        </p>
        <p class="mb-4">
            ID:
            <span class="font-weight-bold">{{ data.uuid }}</span>
        </p>

        <template v-if="data.correct !== null">
            <p class="mb-2">
            <span class="font-weight-bold">Informacje o korekcie:</span>
            </p>
            <p class="mb-2">
                Kwota przed korektą:
                <span class="font-weight-bold">{{ data.correct.amountBeforeCorrect.toFixed(2) }} zł</span>
            </p>
            <p class="mb-2">
                Powód korekty:
                <span class="font-weight-bold">{{ data.correct.reasonCorrect }}</span>
            </p>
            <p class="mb-2">
                Data korekty:
                <span class="font-weight-bold">{{ data.correct.date }}</span>
            </p>
            <p class="mb-4">
                Kwota skorygowana przez:
                <span class="font-weight-bold">{{ data.correct.author.name }}</span>
            </p>
        </template>
    </div>
</template>
