<script>
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/locale/pl';
    import WalletOperationInfo from '@/components/subaccount/wallet-operation-info'

    import Repository from "../../app/repository/repository-factory";
    import EventBus from "../../event-bus";

    const WalletRepository = Repository.get("WalletRepository");

    export default {
        props: {
            name: {
                type: String,
                required: true
            },
            excelFilename: {
                type: String,
                required: true
            },
            walletUuid: {
                type: String,
                required: true
            },
            walletVersion: {
                type: Number,
                required: true
            },
            typeAccount: {
                type: Number,
                default: 0
            }
        },
        components: { DatePicker, WalletOperationInfo },
        data() {
            return {
                isBusy: false,
                excelInProgress: false,
                modalInfo: {
                    showModal: false,
                    indexItemToShowInModal: null,
                },
                modalCorrect: {
                    showModal: false,
                    indexItemToShowInModal: null,
                },
                title: "Lista podopiecznych",
                totalRows: 1,
                items: [],
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50],
                filter: "",
                filters: {
                    dateFrom: null,
                    dateTo: null
                },
                filterOn: ['dateFrom', 'dateTo'],
                sortBy: 'createdAt',
                sortDesc: true,
                fields: []
            };
        },
        created() {
            EventBus.$on('operationCorrected', () => {
                this.modalCorrect.showModal = false;
                this.modalCorrect.indexItemToShowInModal = null;
            })
        },
        watch: {
            filters: {
                handler: function () {
                    this.$refs[this.name].refresh()
                },
                deep: true
            }
        },
        methods: {
            showModalInfoFn(index) {
                this.modalInfo.showModal = true;
                this.modalInfo.indexItemToShowInModal = index;
            },
            showModalCorrectFn(index) {
                this.modalCorrect.showModal = true;
                this.modalCorrect.indexItemToShowInModal = index;
            },
            getExcel: function () {
                this.excelInProgress = true;
                const parameters = '?walletUuid=' + this.walletUuid + '&typeAccount=' + this.typeAccount +
                    '&search=' + this.filter + '&dateFrom=' + this.filters.dateFrom + '&dateTo=' + this.filters.dateTo;
                const promise = WalletRepository.getGridOperationsExcel(parameters);

                return promise.then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Operacje_' + this.excelFilename + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    this.excelInProgress = false;
                }).catch(error => {
                    console.log(error);
                    this.excelInProgress = false;
                })
            },
            axiosProvider(ctx) {
                const parameters = '?walletUuid=' + this.walletUuid + '&typeAccount=' + this.typeAccount +
                    '&page=' + ctx.currentPage + '&maxItems=' + ctx.perPage + '&search=' + this.filter +
                    '&orderBy=' + this.sortBy + '&orderDesc=' + this.sortDesc +
                    '&dateFrom=' + this.filters.dateFrom + '&dateTo=' + this.filters.dateTo;

                const promise = WalletRepository.getGridOperations(parameters);

                return promise.then((data) => {
                    const fields = data.data.info.fields;

                    this.totalRows = data.data.info.countAllItems;

                    let fieldsArray = [];
                    Object.entries(fields).forEach(([key, val]) => {
                        if (val.disabled !== true) {
                            val.key = key;
                            fieldsArray.push(val);
                        }
                    });

                    fieldsArray.push({'key': 'action', 'label': "Info"});
                    this.fields = fieldsArray;
                    this.items = data.data.data;

                    return data.data.data || []
                }).catch(error => {
                    console.log(error);
                    return []
                })
            },
        }
    };
</script>

<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body bg">
                    <h4 class="card-title"><i class="text-secondary bx bx-book-bookmark"></i> Lista operacji</h4>
                    <p class="card-title-desc"></p>
                    <div class="row mb-2">
                        <div class="col-sm-12 col-lg-12">
                            <b-form inline>
                                <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                <div class="ml-2 search-box">
                                    <div class="position-relative">
                                        <b-form-input v-model="filter" :debounce="350" placeholder="Szukaj..."></b-form-input>
                                        <i class="bx bx-search-alt search-icon"></i>
                                    </div>
                                </div>
                            </b-form>
                        </div>


                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-2 col-lg-2">
                            <b-form inline class="right">
                                <date-picker
                                        id="dateFrom"
                                        name="dateFrom"
                                        v-model="filters.dateFrom"
                                        :first-day-of-week="1"
                                        value-type="format"
                                        placeholder="od"
                                        lang="pl">
                                </date-picker>
                            </b-form>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <b-form inline>
                                <date-picker
                                        id="dateTo"
                                        name="dateTo"
                                        v-model="filters.dateTo"
                                        :first-day-of-week="1"
                                        value-type="format"
                                        placeholder="do"
                                        lang="pl">
                                </date-picker>
                            </b-form>
                        </div>
                        <div class="col-sm-8 col-lg-8">
                            <div class="text-sm-right">
                                <button type="button" v-on:click="getExcel"
                                        class="btn btn-success waves-effect waves-light mb-2 mr-2">
                                    <i class="fas fa-file-excel mr-1"></i> Export do Excela
                                    <template v-if="excelInProgress">
                                        <b-spinner small class="m-0" variant="light" role="status"
                                                   type="grow"></b-spinner>
                                    </template>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- End search -->
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                        <b-table :style="'min-height:' + 57 * items.length + 'px'"
                                :id="name"
                                :ref="name"
                                :items="axiosProvider"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn">

                            <template v-slot:table-busy>
                                <div class="text-center text-black">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong> Ładowanie danych...</strong>
                                </div>
                            </template>

                            <template v-slot:emptyfiltered>
                                <div class="text-center text-black">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong> Ładowanie danych...</strong>
                                </div>
                            </template>

                            <template v-slot:cell(amountMoney)="data">

                                <template v-if="data.item.sourceOperation.sourceType === 1 && data.item.correct !== null">
                                    <a v-b-tooltip.hover title="Kwota skorygowana - zobacz szczegóły">
                                        <i class="bx bxs-info-circle mr-2 text-danger" @click="showModalInfoFn(data.index)"></i>
                                    </a>
                                </template>

                                <span class="font-weight-bold">
                                    {{ data.value.toFixed(2) }} zł
                                </span>
                            </template>

                            <template v-slot:cell(typeOperation)="data">
                                <span class="badge badge-pill badge-soft-success font-size-12"
                                      :class=" { 'badge-soft-danger': data.item.amountMoney < 0,
                                    'badge-soft-success': data.item.amountMoney > 0 }">
                                    {{ data.value }}
                                </span>
                            </template>

                            <template v-slot:cell(action)="row">
                                <ul class="list-inline font-size-20 contact-links mb-0">
                                    <li class="list-inline-item px-2">
                                        <a v-b-tooltip.hover title="Szczegóły transakcji">
                                            <i class="bx bxs-info-circle" @click="showModalInfoFn(row.index)"></i>
                                        </a>
                                    </li>
                                </ul>
                            </template>

                        </b-table>

                        <b-modal v-if="modalCorrect.showModal" v-model="modalCorrect.showModal"
                                 title="Korekta operacji" centered>
                            <p class="mb-2">
                                Typ operacji:
                                <span class="font-weight-bold">{{ items[modalCorrect.indexItemToShowInModal].typeOperation }}</span>
                            </p>
                            <p class="mb-2">
                                Kwota:
                                <span class="font-weight-bold">{{ items[modalCorrect.indexItemToShowInModal].amountMoney }} zł</span>
                            </p>
                            <p class="mb-2">
                                Data:
                                <span class="font-weight-bold">{{ items[modalCorrect.indexItemToShowInModal].time }}</span>
                            </p>
                            <p class="mb-2">
                                Utworzone przez:
                                <span class="font-weight-bold">{{ items[modalCorrect.indexItemToShowInModal].createdBy.name }}</span>
                            </p>

                            <template v-slot:modal-footer>
                                <b-button variant="primary" @click="modalCorrect.showModal = false">Zamknij
                                </b-button>
                            </template>
                        </b-modal>

                        <b-modal v-if="modalInfo.showModal" v-model="modalInfo.showModal"
                                 title="Szczegóły transakcji" centered>

                            <wallet-operation-info :data="items[modalInfo.indexItemToShowInModal]">
                            </wallet-operation-info>

                            <template v-slot:modal-footer>
                                <b-button variant="primary" @click="modalInfo.showModal = false">Zamknij</b-button>
                            </template>
                        </b-modal>


                    </div>
                    <div class="row">
                        <div class="col">
                            <template v-if="totalRows === 0">
                                <div class="d-flex justify-content-center mt-3">
                                    <b>Nie znaleziono elementów</b>
                                </div>
                            </template>
                            <template v-if="totalRows > 0">
                                <div class="dataTables_paginate paging_simple_numbers float-left ml-2">
                                    Znaleziono {{ totalRows }} rekordów.
                                </div>
                            </template>
                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded mb-0">
                                    <!-- pagination -->
                                    <b-pagination v-model="currentPage" :total-rows="totalRows"
                                                  :per-page="perPage"
                                                  first-number last-number></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
